import cartFields from "@api/fragments/cart";

const addProductToCart = /* GraphQL */ `
  mutation addProductToCart($input: CartAddInput) {
    cart(input: { add: $input }) {
      add {
        cart {
          ...cartFields
        }
      }
    }
  }
  ${cartFields}
`;

export default addProductToCart;
