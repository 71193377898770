import { GRAPHQL_ENDPOINT, SITE } from "@api/constants";
import getCustomerAndCart from "@api/queries/get-customer-and-cart";
import Cart from "@api/types/cart";
import Customer from "@api/types/customer";

type CustomerStateRequest = {
  data: {
    cart: Cart;
    customer: Customer;
  };
};

/**
 * Fetches
 */
const fetchUserState = async (token: string): Promise<{ cart: Cart; customer: Customer }> => {
  const operationName = /query ([a-zA-z0-9]*) /.exec(getCustomerAndCart)[1];

  const body = {
    operationName,
    query: getCustomerAndCart,
    variables: {},
  };

  const response = await fetch(GRAPHQL_ENDPOINT, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-CORE-SITE": SITE,
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(body),
  });
  const responseBody = (await response.json()) as CustomerStateRequest;
  return responseBody?.data;
};

export default fetchUserState;
