import cartFields from "@api/fragments/cart";

const getCustomerAndCart = /* GraphQL */ `
  query getCustomerAndCart {
    customer {
      id
      email
      firstName
      lastName
      guest
    }
    cart: cart2 {
      ...cartFields
    }
  }
  ${cartFields}
`;

export default getCustomerAndCart;
