import cartFields from "@api/fragments/cart";

const addProductToCart = /* GraphQL */ `
  mutation changeProductInCart($input: CartChangeInput) {
    cart(input: { change: $input }) {
      change {
        cart {
          ...cartFields
        }
      }
    }
  }
  ${cartFields}
`;

export default addProductToCart;
