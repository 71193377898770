import { GET_TOKEN_ENDPOINT, SITE } from "@api/constants";
import cookie from "js-cookie";

type GetTokenResponse = {
  token: string;
};

const getToken = async (): Promise<string> => {
  const cookieToken = cookie.get("token");

  const response = await fetch(GET_TOKEN_ENDPOINT, {
    method: "POST",
    headers: {
      Authorization: cookieToken ? `Bearer ${cookieToken}` : "",
      "Content-Type": "application/json",
      "X-CORE-SITE": SITE,
    },
  });
  const responseBody = (await response.json()) as GetTokenResponse;
  return responseBody?.token;
};

export default getToken;
