import { GRAPHQL_ENDPOINT, SITE } from "@api/constants";
import changeProductInCartMutation from "@api/mutations/change-product-in-cart";
import Cart from "@api/types/cart";
import ChangeProduct from "@api/types/change-product";

type MutationResult = {
  data: {
    cart: {
      change: {
        cart: Cart;
      };
    };
  };
};

/**
 * Add a product to cart.
 */
const changeProductInCart = async (token: string, input: ChangeProduct): Promise<Cart> => {
  const body = {
    query: changeProductInCartMutation,
    variables: {
      input,
    },
  };

  const response = await fetch(GRAPHQL_ENDPOINT, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-CORE-SITE": SITE,
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(body),
  });
  const responseBody = (await response.json()) as MutationResult;
  return responseBody?.data.cart.change.cart;
};

export default changeProductInCart;
