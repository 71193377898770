import { GRAPHQL_ENDPOINT, SITE } from "@api/constants";
import addProductToCartMutation from "@api/mutations/add-product-to-cart";
import AddProduct from "@api/types/add-product";
import Cart from "@api/types/cart";

type MutationResult = {
  data: {
    cart: {
      add: {
        cart: Cart;
      };
    };
  };
};

/**
 * Add a product to cart.
 */
const addProductToCart = async (token: string, input: AddProduct): Promise<Cart> => {
  const body = {
    query: addProductToCartMutation,
    variables: {
      input,
    },
  };

  const response = await fetch(GRAPHQL_ENDPOINT, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "X-CORE-SITE": SITE,
      Authorization: "Bearer " + token,
    },
    body: JSON.stringify(body),
  });
  const responseBody = (await response.json()) as MutationResult;
  return responseBody?.data.cart.add.cart;
};

export default addProductToCart;
