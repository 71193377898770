import cartFields from "@api/fragments/cart";

const removeProductFromCart = /* GraphQL */ `
  mutation removeProductFromCart($input: CartRemoveInput) {
    cart(input: { remove: $input }) {
      remove {
        cart {
          ...cartFields
        }
      }
    }
  }
  ${cartFields}
`;

export default removeProductFromCart;
